import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Gallerypage from './Gallerypage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css"; // Ensure React UI libraries are styled correctly
//import { Amplify } from 'aws-amplify'
//import awsconfig from './aws-exports'
import OtterCamComponent from './components/OtterCamComponent';
//Amplify.configure(awsconfig) // Configures the Amplify libraries with the cloud backend set up via the Amplify CLI

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
          <Route path="gallerypage" element={<Gallerypage />} />
          <Route path="otters" element={<OtterCamComponent />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
